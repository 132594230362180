import { useMemo } from "react";
import { WorkingHour, WorkingHourItem } from "types/business.types";

type Weekday = "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday";

const useSortWorkingHours = (workingHours: WorkingHour | undefined) => {
	const sorting = useMemo(() => {
		if (!workingHours) return [];
		const times: { d: string; time: WorkingHourItem[] }[] = [];
		Object.entries(workingHours).forEach(function ([day, time]) {
			times.push({ d: day, time });
		});
		const sorter = {
			// "sunday": 0, // << if sunday is first day of week
			monday: 1,
			tuesday: 2,
			wednesday: 3,
			thursday: 4,
			friday: 5,
			saturday: 6,
			sunday: 7,
		};

		times.sort((a, b) => {
			let day1 = a.d.toLowerCase() as Weekday;
			let day2 = b.d.toLowerCase() as Weekday;
			return sorter[day1] - sorter[day2];
		});
		return times;
	}, [workingHours]);

	return sorting;
};

export default useSortWorkingHours;
