import { memo } from "react";
import classNames from "classnames";
import useSortWorkingHours from "hooks/useSortWorkingHours";
import useTranslation from "next-translate/useTranslation";
import { WorkingHour } from "types/business.types";
import { formatTime24, getNameOfDay } from "utils/utils";

type Props = {
	workingHours: WorkingHour;
};

function WorkingHours({ workingHours }: Props) {
	const today = getNameOfDay().toLowerCase();
	const sorting = useSortWorkingHours(workingHours);
	const { t } = useTranslation("common");

	return (
		<ul className="space-y-2">
			{sorting.map(({ d, time }) => (
				<li
					key={d}
					className={classNames("flex align-center flex-wrap space-x-4", {
						"text-primary-500": today == d,
					})}
				>
					<span className="flex-1 capitalize">
						{t(`workingHours.weekdays.${d}`)}:
						{/* {today == d && <span className="pl-2 text-sm">(Today)</span>} */}
					</span>
					<span className="flex-none text-sm leading-6">
						{(time.length > 0 && (
							<>
								{formatTime24(time[0]?.start)} – {formatTime24(time[0]?.end)}
							</>
						)) || <>{t("workingHours.closed")}</>}
					</span>
				</li>
			))}
		</ul>
	);
}

export default memo(WorkingHours);
